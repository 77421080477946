import { FC, useContext, useEffect, useMemo } from "react";
import {
    Box, Button,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Paper, Select,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {
    AppContext,
    CartContext,
    CatalogContext,
    FavoritesContext,
    NotificationsContext,
    UserContext,
    WaitListContext
} from "../../../store";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import LoadingPaper from "../../LoadingPaper";
import { CatalogCard } from "../../Item";
import ItemCardModal from "../../ItemCardModal";
import { FilterProps, ItemsListViewMode } from "../../../store/catalog";
import { Tree, TreeLeaf } from "../../../common/tree";
import CatalogWideCard from "../../Item/CatalogWideCard";
import List from "../../List";
import { toJS } from "mobx";
import FiltersBlock from "../../FiltersBlock";
import EmailButtonCart from "../../NotificationEmailSetup/EmailButtonCart";
import VerifiedEmail from "../../NotificationEmailSetup/VerifiedEmail";
type ViewType = 'items' | 'categories' | 'emptyChildren';
export type ViewState = {
    readonly type: ViewType;
};

type Props = {
    readonly tree?: Tree;
    readonly filterProps?: FilterProps;
    readonly sector?: string;
    readonly path?: string;
    readonly basePath?: string;
    readonly filters?: Record<number, any>;
    readonly onFiltersChange?: (value: Record<number, any>) => void;
    readonly search?: string;
    readonly group?: string;
    readonly sort?: string;
    readonly filterDisabled?: boolean;
    readonly loadNextPage?: () => void;
    readonly itemsMode?: ItemsListViewMode;

    readonly onViewChange?: (s: ViewState) => void;
};

const onLink = (n: TreeLeaf) => {
    if (!Object.keys(n.children).length) return n.aliasfull;

    return onLink(Object.values(n.children)[0]);
}

const StockWarningDialog = (p: {
    readonly stock: number;
    readonly inCart: number;
    readonly onAcceptClick: () => void;
}) => {

    return (
        <Box>
            <Typography variant="h6" color="error" >
                Внимание!
            </Typography>
            <Typography sx={{ mt: 1 }}>
                <strong>На складе осталось товаров: {p.stock} <br /></strong>
                В корзину добавлено: {p.inCart} <br />
                Товаров под заказ: {p.inCart - p.stock} <br /><br />
                По стоимости и срокам доставки недостающих единиц уточняйте у оператора
            </Typography>
            <Button
                sx={{ mt: 2 }}
                variant="contained"
                onClick={p.onAcceptClick}
                children="Хорошо"
            />
        </Box>
    );
};

const _createCard = (mode: ItemsListViewMode, item: { item }) => {
    const app = useContext(AppContext);
    const cart = useContext(CartContext);
    const favorites = useContext(FavoritesContext);
    const waitList = useContext(WaitListContext);
    const notifications = useContext(NotificationsContext);
    const user = useContext(UserContext);
    const navigate = useNavigate();
    const notify = notifications.notify;
    const favorite = favorites.includes(user.user?.id, item.item.code);
    const inCartCount = cart.itemsCount(user.user?.id, item.item.code);

    const handleClick = () => {
        navigate(`/catalog/item/${item.item.id}`);
    };

    return (
        <Box
            component={mode === 'grid' ? CatalogCard : CatalogWideCard}
            item={item.item}
            inCartCount={inCartCount}
            favorite={favorite}
            onFavoritesClick={async () => {
                const { orderPrice, ...cartItem } = item.item;
                if (favorite) {
                    await favorites.remove(cartItem, user.user?.id);
                    app.alert(`Удалено из избранного`);
                }
                else {
                    await favorites.add(cartItem, user.user?.id);
                    app.alert(`Добавлено в избранное`);
                }

            }}
            onAddToCartClick={async count => {
                const { orderPrice, ...cartItem } = item.item;
                await cart.addWithAmount(cartItem, count, user.user?.id);

                if (cartItem.count <= 0 && user.user?.matrixCode != 1) {
                    await waitList.add(cartItem, user.user?.id);
                    if (!notify.email) {
                        app.showDialog(<EmailButtonCart />, { fullWidth: false });
                        app.alert(`Добавлено в корзину и лист ожидания`);
                    }
                    else {
                        app.showDialog(<VerifiedEmail />, { fullWidth: false })
                        app.alert(`Добавлено в корзину и лист ожидания`);
                    }
                }
                else {
                    if (inCartCount + count > item.item.count) {
                        app.showDialog(<StockWarningDialog
                            stock={item.item.count}
                            inCart={inCartCount + count}
                            onAcceptClick={() => app.closeDialog()}
                        />, { fullWidth: false });
                    }

                    app.alert(`Добавлено в корзину`);
                }

            }}
            onClick={handleClick}
        />
    );
}
const MyCatalogCard = observer((item: any) => _createCard('grid', item));
const MyCatalogWideCard = observer((item: any) => _createCard('row', item));

const SubCategories = (p: { leaves: Record<string, TreeLeaf>, level: number }) => {
    const theme = useTheme();
    const leaves = Object.values(p.leaves);

    return (<>
        {leaves.map(c => (
            <Box
                key={c.id}
                sx={{
                    pl: 1,
                    borderLeft: "2px solid #8f95b2",
                    py: 1,
                }}>
                <Link
                    underline="none"
                    component={RouterLink}
                    to={`/catalog/${c.aliasfull}`}
                    sx={{
                        color: "#8F95B2",
                        borderRadius: "0 16px 16px 0",
                        '&:hover': { color: theme.palette.info.contrastText },
                        justifyContent: "start",
                        textAlign: "start",
                        width: "max-content",
                        maxWidth: "200px",
                    }}
                >
                    {c.label}
                </Link>
                {c.children && <SubCategories leaves={c.children} level={p.level + 1} />}
            </Box>
        ))}
    </>);
}

const Categories = (p: { leaves: Record<string, TreeLeaf> }) => {
    const leaves = Object.values(p.leaves);

    return (<>
        {leaves.map(c => {
            const hasChildren = Object.keys(c.children).length > 0;
            return (
                <Box key={c.id}>
                    <Typography component={hasChildren ? undefined : RouterLink} to={`/catalog/${c.aliasfull}`} fontSize="1.2rem" fontWeight="bold" color="black" py={1}>{c.label}</Typography>
                    {c.children && <SubCategories leaves={c.children} level={0} />}
                </Box>
            )
        })}
    </>);
};

const CatalogTreeView: FC<Props> = p => {
    const location = useLocation();
    const navigate = useNavigate()
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const catalog = useContext(CatalogContext);

    const path = p.path || 'masla';
    let idx = path.lastIndexOf('/');
    const topPath = idx < 0 ? undefined : path.substring(0, idx);
    let topCategories = catalog.getTreeLevel(p.sector, topPath)?.list;
    const topCategoriesMobile = catalog.getTreeLevel(p.sector)?.list || {};
    const childrenLength = topCategories ? Object.values(topCategories).length != 1 : false

    if (!topPath) topCategories = [];

    const treeLevel = catalog.getTreeLevel(p.sector, p.search ? p.path : path, p.filters, p.search, p.group, p.sort);

    const levelType: ViewType = useMemo(() => {
        if (!treeLevel) return undefined;
        if (treeLevel.type == 'items') return 'items';

        for (const cat in treeLevel.list)
            if (cat.length) return 'categories';

        return 'emptyChildren';

    }, [treeLevel]);

    useEffect(() => {
        if (!p.onViewChange) return;

        p.onViewChange({
            type: levelType,
        });


    }, [levelType]);
    return (<>
        {topCategories ? (<>

            <Grid container sx={{ pb: 1 }} >
                <Grid item xs={12} lg={3} >
                    {isSmall && (<>
                        <FormControl fullWidth sx={{ pb: 3 }}>
                            <InputLabel id="demo-simple-select-label">Категория</InputLabel>
                            <Select
                                label="Категория"
                                onChange={e => navigate(`/catalog/${e.target.value}`)}
                                value={`${path}/`}
                            >
                                {Object.values(topCategoriesMobile).map(n => (
                                    <MenuItem key={n.id} value={n.aliasfull}>{n.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {treeLevel?.type === 'categories' && (
                            <Categories leaves={treeLevel.list} />
                        )}
                    </>)}
                </Grid>
            </Grid>

            {treeLevel?.type !== 'categories' && (<>
                <Box sx={{ flexWrap: "wrap", display: "flex", flexDirection: "row", gap: "6px", pb: 2 }}>
                    {childrenLength && (<>
                        {Object.values(topCategories).map(w => (
                            <Box
                                key={w.id}
                                component={RouterLink}
                                to={`/catalog/${onLink(w)}`}
                                sx={{
                                    borderRadius: "0.56rem",
                                    color: w.aliasfull === `${path}/` ? "white" : "black",
                                    overflow: "visible",
                                    padding: "6px 10px",
                                    backgroundColor: w.aliasfull === `${path}/` ? "#8f95b2" : "#e6e8f0",
                                    transition: "0.3s"
                                }}>
                                <Typography key={w.label} sx={{ '&:hover': { textDecoration: "underline" }, lineHeight: '200%' }} fontSize="0.75rem" noWrap children={w.label} />
                            </Box>
                        ))}
                    </>)}

                </Box>
                <Grid container spacing={2} direction={{ xs: 'column-reverse', lg: 'row' }}>
                    <Grid item
                        xs={12}
                        md={p.filterDisabled ? undefined : p.path ? 8 : 12}
                        lg={p.filterDisabled ? undefined : p.path ? 9 : 12}
                    >
                        <Box sx={{ mb: 2 }}>
                            <List
                                variant={p.itemsMode === 'grid' ? undefined : 'grid'}
                                component={p.itemsMode === 'grid' ? MyCatalogWideCard : MyCatalogCard}
                                key={(p.itemsMode || '') + location.search}
                                items={treeLevel}
                                loadNext={p.loadNextPage}
                                groups={[
                                    item => ({ text: `В наличии: ${item.count > 0 ? 'Есть' : 'Нет'}`, condition: () => p.group == '1' }),
                                    item => ({ text: `Бренд: ${item.brand}`, condition: () => p.group == '2' }),
                                ]}
                            />
                            {treeLevel?.similar?.list?.length ? (<>
                                <Typography sx={{ mt: 2, mb: 1 }} variant="h6">
                                    Аналоги
                                </Typography>
                                <List
                                    variant="grid"
                                    component={MyCatalogCard}
                                    key={location.search + '_c'}
                                    items={treeLevel.similar}
                                />
                            </>) : undefined}
                        </Box>
                    </Grid>

                    {!p.filterDisabled && (
                        <Grid item xs={12} md={path ? 4 : 3} lg={path ? 3 : 12}>
                            {p.filterProps ? (
                                <Box sx={{ position: "sticky", top: "90px" }}>
                                    <Paper sx={{ overflowY: "scroll", maxHeight: "85vh" }}>
                                        <FiltersBlock
                                            selected={p.filters}
                                            props={toJS(p.filterProps.list) as any}
                                            onSelect={f => p.onFiltersChange && p.onFiltersChange(f)}
                                        />

                                    </Paper>
                                </Box>
                            ) : (
                                !p.search && path && (
                                    <LoadingPaper />
                                )
                            )}
                        </Grid>
                    )}
                </Grid>
            </>)}
        </>)
            : (<LoadingPaper />)}
    </>);
};

export default observer(CatalogTreeView);
