import { FC, ReactElement, createRef, useContext, useEffect, useRef, useState } from "react";
import {
    AppBar, Badge, BadgeProps, Box,
    Button,
    Container, Divider, Drawer, Fab,
    IconButton, LinearProgress,
    Popover, Slide, styled,
    Toolbar, Typography, useMediaQuery,
    useScrollTrigger, useTheme, Zoom
} from "@mui/material";
import { AppContext, AuthContext, BonusContext, CartContext, UserContext } from "../../../../store";
import {
    Outlet,
    useNavigate,
    useSearchParams,
    useLocation, Link,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import CartIcon from '../../../../icons/cart.svg';
import MenuIcon from '../../../../icons/menu_mobile.svg';
import CloseMenuIcon from '../../../../icons/close.svg';
import MenuBar from "../../../MenuBar";
import DebtInfo from "../../../DebtInfo";
import ErrorView from "../../ErrorView";
import delivery from "../../../../images/delivery.png";
import deliveryMobile from "../../../../images/deliveryMobile.png";
import Logo from "../../../Logo";
import ItemsListMobile from "./MenuItemsListMobile";
import ItemsList from "./MenuItemsList";
type Props = {
};

type Test = {
    children: ReactElement;
}

const InfoPopup = () => {

    const isMobile = useMediaQuery('(max-width: 700px)', { noSsr: true });

    return (<>
        <Typography variant="subtitle1">Важное уведомление!</Typography>
        <br />
        <a href="tel:+78332735770">
            <Box
                component="img"
                src={isMobile ? deliveryMobile : delivery}
                sx={{
                    maxWidth: '100%',
                }}
            />
        </a>
    </>);
}

function HideOnScroll(props: Test) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        color: 'white',
    },
}));

const CartButton = observer((p: { mobile?: boolean }) => {
    const location = useLocation();
    const cart = useContext(CartContext);

    const summary = cart.summary;

    const count = summary?.totalItems || 0;

    return (
        <Zoom in={!!count && location.pathname !== '/cart'} unmountOnExit>
            <StyledBadge
                badgeContent={count}
                color="warning"
                sx={{ zIndex: 2 }}
            >
                <Link to="/cart">
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{
                            borderRadius: p.mobile ? '12px' : '15px',
                            zIndex: 1,
                            width: p.mobile ? 36 : 54,
                            height: p.mobile ? 36 : 54,
                        }}
                    >
                        <CartIcon />
                    </Fab>
                </Link>
            </StyledBadge>
        </Zoom>
    );
});


const WebView: FC<Props> = () => {
    const auth = useContext(AuthContext);
    const app = useContext(AppContext);
    const user = useContext(UserContext);
    const bonus = useContext(BonusContext);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [, setSearchQuery] = useState('');
    const appBarRef = useRef();
    const theme = useTheme();
    const location = useLocation();

    const isSmallMobile = useMediaQuery('(max-width: 450px)', { noSsr: true });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const logOut = () => auth.logOut();
    const wasLoggedIn = auth.wasLoggedIn;
    const hasPendingRequests = app.hasPendingRequests;

    const stockSyktyvkar = '2246f57c-5956-11ef-ab64-00155d1e0601';
    const popupId = 'StockPopup';
    const popupPredicate = () => user.user.matrixCode === 56;

    const showOncePopup = app.scheduledTask(popupId, popupPredicate);
    const [showBonusAlert, acceptBonusAlert] = app.trueUntilCanceled('BonusSystemAcceptAlert', () => !!bonus.status?.enabled);

    useEffect(() => {
        if (!showBonusAlert) return;

        app.alert(<>
            Пользуясь сайтом вы даете согласие на участие в <Link target="_blank" to="/static/bonus-agreement.pdf">системе лояльности</Link>.
        </>,
            {
                time: null,
                severity: 'warning',
                action: (
                    <Button
                        color="inherit"
                        size="medium"
                        onClick={() => {
                            acceptBonusAlert();
                            app.closeSnackbar();
                        }}
                    >
                        Принять
                    </Button>
                )
            }
        );

    }, [showBonusAlert]);

    if (showOncePopup) {
        app.showDialog(<InfoPopup />, { fullWidth: false });
    }

    useEffect(() => {
        if (!wasLoggedIn) return;

        if (
            showOncePopup ||
            !popupPredicate()
        )
            return;

        app.showDialog(<InfoPopup />, { fullWidth: false });

    }, [popupPredicate(), wasLoggedIn]);

    useEffect(() => {
        setSearchQuery(searchParams.get('q') || '');
    }, [searchParams.get('q')]);

    useEffect(() => {
        setAnchorEl(null);
        setDebitAnchorEl(null);
    }, [isMobile, location]);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [debitAnchorEl, setDebitAnchorEl] = useState<null | HTMLElement>(null);

    const debitId = !!debitAnchorEl ? 'simple-popover' : undefined;

    const debtInfo = user?.debtInfo;
    const appError = app.error;

    if (appError) return <ErrorView />;

    const refComponent = createRef<HTMLElement>();

    const [topInfoHeight, setTopInfoHeight] = useState<number>();

    useEffect(() => {
        setTopInfoHeight(refComponent.current.getBoundingClientRect().height);
    });

    return (<>
        <Box sx={{ position: 'fixed', width: '100%', zIndex: theme.zIndex.modal - 1 }}>
            {hasPendingRequests && <LinearProgress color="secondary" />}
        </Box>
        <Container maxWidth="xl"
            sx={{
                paddingLeft: { xs: 2, lg: 6 },
                paddingRight: { xs: 2, lg: 6 },
            }}
        >
            <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <HideOnScroll>
                    <AppBar ref={appBarRef} color="inherit" sx={{ boxShadow: 'none' }}>
                        <Toolbar sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            minHeight: '69px !important'
                        }}>
                            <Logo
                                size={isSmallMobile && !!debtInfo ?
                                    'extra-small' : (
                                        isMobile ? 'small' : 'normal'
                                    )
                                }
                            />
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}>
                                <Box>
                                    <CartButton mobile />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                    {debtInfo && (<>
                                        <Button
                                            aria-describedby={debitId}
                                            sx={{
                                                fontSize: '0.6250rem',
                                                lineHeight: '1.2',
                                                fontWeight: 'bold',
                                                ml: 1,
                                                maxWidth: 105,
                                                minHeight: 36,
                                                textAlign: 'left',
                                                px: 1.5,
                                            }}
                                            color="warning"
                                            size="small"
                                            variant="outlined"
                                            onClick={e => {
                                                setDebitAnchorEl(e.currentTarget)
                                            }}
                                        >
                                            Дебиторская задолженность
                                        </Button>
                                        <Popover
                                            id={debitId}
                                            open={!!debitAnchorEl}
                                            anchorEl={debitAnchorEl}
                                            onClose={() => setDebitAnchorEl(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            sx={{
                                                '& .MuiPaper-root': {
                                                    p: 2
                                                }
                                            }}
                                        >
                                            <DebtInfo />
                                        </Popover>
                                    </>)}
                                    <IconButton
                                        size="large"
                                        edge="end"
                                        color="inherit"
                                        aria-label="menu"
                                        onClick={() => setAnchorEl(appBarRef.current)}
                                    >
                                        {anchorEl ? <CloseMenuIcon width={32} height={32} /> : <MenuIcon />}
                                    </IconButton>
                                    <Popover
                                        id="menu-appbar"
                                        open={!!anchorEl}
                                        anchorEl={anchorEl}
                                        onClose={() => setAnchorEl(null)}
                                        marginThreshold={0}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        sx={{
                                            '& .MuiPopover-paper': {
                                                width: '100%',
                                                maxWidth: 'initial',
                                                p: isSmallMobile ? 1 : 2,
                                                top: '70px!important',

                                            },
                                        }}
                                    >
                                        <Box sx={{
                                            pb: isSmallMobile ? 1 : 2,
                                            pl: 1
                                        }}>
                                            <Button component={Link} to="/profile" variant="text" size="large">
                                                <Typography variant="subtitle1" color={theme.palette.primary.main}>
                                                    {user.user?.fullName}
                                                </Typography>
                                            </Button>
                                        </Box>
                                        <MenuBar children={<ItemsListMobile />} />
                                    </Popover>
                                </Box>
                            </Box>
                        </Toolbar>
                    </AppBar>
                </HideOnScroll>
                <Toolbar sx={{ minHeight: '69px !important' }} />
            </Box>
            <Drawer
                sx={{
                    whiteSpace: 'initial',
                    display: { xs: 'none', sm: 'inline-block' },
                    mr: { sm: 1, md: 1, lg: 6 },
                    position: 'relative',
                    width: {
                        sm: 247,
                        md: 237,
                        lg: 197,
                    },
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        overflow: "hidden",
                        height: "100vh",
                        left: 'auto',
                        width: 257,
                        transform: {
                            sm: 'translateX(-20px)',
                            lg: 'translateX(-60px)',
                        },
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <Box ref={refComponent} sx={{ px: 2, pt: 4, wordWrap: 'break-word', wordBreak: 'break-word' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Logo
                            size={isSmallMobile && !!debtInfo ?
                                'extra-small' : (
                                    isMobile ? 'small' : 'normal'
                                )
                            }
                        />
                    </Box>
                    <Divider sx={{ mt: 4, mb: 1 }} variant="fullWidth" />
                </Box>
                <Box sx={{ position: "fixed", left: 10, top: topInfoHeight, bottom: 10, overflow: "scroll" }}>
                    <ItemsList />
                </Box>
            </Drawer>
            <Box sx={{
                whiteSpace: 'initial',
                display: 'inline-block',
                position: 'relative',
                width: {
                    xs: '100%',
                    sm: 'calc(100% - 247px - 10px)',
                    md: 'calc(100% - 237px - 10px)',
                    lg: 'calc(100% - 197px - 60px)',
                },
            }} >
                <Outlet />
            </Box>
        </Container>
    </>);
};

export default observer(WebView);