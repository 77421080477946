import { FC, ReactNode, SyntheticEvent, useContext } from "react";
import {
    Box, BoxProps,
    Card as MuiCard,
    CardActions,
    Chip, IconButton,
    Typography, useMediaQuery, useTheme,
} from "@mui/material";
import Stepper from "../../Stepper";
import BatchItemsPrice from "../BatchItemsPrice";
import Title from "../Title";
import FavoriteNotAddedIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteAddedIcon from '@mui/icons-material/Favorite';
import InCartIcon from '@mui/icons-material/ShoppingBasket';
import ItemImage from "../../ItemImage";
import { UserContext } from "../../../store";
import BonusBadge from "../../bonus/BonusInfo/BonusBadge";
import { Dayjs } from "dayjs";

type Props = {
    readonly imgSrc?: string;
    readonly label: string;
    readonly count: number;
    readonly stock: number;
    readonly defaultStock?: number;
    readonly defaultStockDate?: Dayjs;
    readonly price: number;
    readonly oldPrice?: number;
    readonly packageSize: number;
    readonly articl: string;
    readonly cnpCode: string;
    readonly action: ReactNode;
    readonly inCartCount: number;
    readonly outOfStock?: boolean;
    readonly favorite?: boolean;
    readonly expanded?: boolean;
    readonly onFavoritesClick?: () => void;
    readonly onClick?: BoxProps['onClick'];
    readonly alwaysExpanded?: boolean;
    readonly hasSimilarItems?: boolean;
    readonly bonusValue?: number;

    readonly onCountChange?: (count: number) => void;
    readonly onHoverChange?: (hover: boolean) => void;
}

const c = (e: SyntheticEvent, fn: CallableFunction) => (e.stopPropagation(), fn && fn());

const StockLine = (p: { stock: number, defaultStock?: number, defaultStockDate?: Dayjs, warning: boolean }) => {
    const theme = useTheme();

    return (
        <strong 
            style={{ color: p.warning ? theme.palette.warning.main : undefined }}
        >
            <>На складе: {p.stock}</>
            {p.defaultStock ? (<>{" "}
                ({p.defaultStock}{" "}
                {p.defaultStockDate ? p.defaultStockDate.format('DD.MM') : 'в Кирове'})
            </>) : ''}
        </strong>
    );
}

const Card: FC<Props> = (p) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const expanded = isDesktop && !p.alwaysExpanded && p.expanded;

    const stockWarning = p.inCartCount + p.count > p.stock;

    const user = useContext(UserContext);
    const showStock = !user.user || !user.user.parentPriceCode;

    return (
        <Box
            sx={{
                position: 'relative',
                cursor: p.onClick && 'pointer',
            }}
            onClick={p.onClick}
        >
            <MuiCard
                sx={{
                    zIndex: expanded ? 1 : 'initial',
                    transform: expanded ? 'translateX(0%)' : 'initial',
                    width: "100%"
                }}
                onMouseOver={p.onHoverChange && (() => p.onHoverChange(true))}
                onMouseOut={p.onHoverChange && (() => p.onHoverChange(false))}
            >
                <Box sx={{ p: 1 }}>
                    <ItemImage variant="card" src={p.imgSrc} alt={p.label} />
                </Box>
                <IconButton
                    sx={{ right: 3, top: 3, position: 'absolute', color: "#FF754C", zIndex: 50, }}
                    onClick={e => c(e, p.onFavoritesClick)}
                >
                    {p.favorite ? <FavoriteAddedIcon color="warning" /> : <FavoriteNotAddedIcon />}
                </IconButton>
                {p.hasSimilarItems && (
                    <Chip
                        size="small"
                        label="Есть аналоги"
                        color="info"
                        sx={{
                            cursor: 'inherit',
                            color: theme.palette.secondary.main,
                            position: 'absolute',
                            top: 0, left: 0,
                            m: 1,
                            zIndex: 50,
                        }}
                    />
                )}
                {p.inCartCount > 0 && (
                    <InCartIcon
                        sx={{
                            position: 'absolute',
                            right: 11,
                            top: 40,
                            color: theme.palette.warning.main,
                            zIndex: 50,
                        }}
                    />
                )}
                <Box sx={{ p: 1 }}>
                    <Title value={p.label} />
                    {/* {(!isDesktop || p.alwaysExpanded || expanded) && (<> */}
                    {(p.alwaysExpanded || expanded) && (<>
                        <Box
                            sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                m: 1,
                                p: 1,
                                height: 150,
                                backgroundColor: "#081735ad",
                                borderRadius: "12px",
                            }}>
                            <Typography
                                fontSize={14}
                                variant="subtitle2"
                                color="white"
                            >
                                {p.packageSize ? <strong>В упаковке: {p.packageSize} <br /></strong> : ''}
                                {p.articl ? <>Артикул: {p.articl} <br /></> : ''}
                                {p.cnpCode ? <>Код ДА: {p.cnpCode} <br /></> : ''}
                                <StockLine 
                                    stock={p.stock}
                                    warning={stockWarning}
                                    defaultStock={p.defaultStock}
                                    defaultStockDate={p.defaultStockDate}
                                />
                            </Typography>
                        </Box>
                    </>)}

                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        {!p.bonusValue ? undefined : (
                            <BonusBadge
                                mode="catalogItem"
                                value={p.bonusValue}
                            />
                        )}
                        <BatchItemsPrice count={p.count} price={p.price} oldPrice={p.oldPrice} />
                    </Box>

                    <CardActions
                        sx={{
                            justifyContent: 'space-between',
                            padding: "10px 0 0 0",
                            '& .MuiButtonBase-root': {
                                borderRadius: "12px"
                            }
                        }}
                        disableSpacing
                    >
                        {p.onCountChange ? (
                            <Stepper
                                value={p.count}
                                onChange={p.onCountChange}
                                onClick={e => e.stopPropagation()}
                            />
                        ) : (
                            <Chip label={p.count} />
                        )}
                        {p.action}
                    </CardActions>
                </Box>
            </MuiCard >
        </Box >
    );
}

export default Card;