import { FC, useContext, useEffect, useState } from "react";
import {
    Box, Divider,
    Unstable_Grid2 as Grid,
    Paper, Stack, Typography, useTheme, Button,
    Skeleton,
    CircularProgress,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import {
    AuthContext,
    BonusContext,
    OrderContext,
} from "../../../store";
import { Link, Link as RouterLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import LoadingPaper from "../../../components/LoadingPaper";
import Date from "../../../components/Date";
import TotalPrice from "../../../components/Order/TotalPrice";
import AppList, { Patch } from "../../../components/List";
import BonusInfo from "../../../components/bonus/BonusInfo";
import OrderWideCardRetail from "../../components/Item/OrderWideCardRetail";
import RubleIcon from '@mui/icons-material/CurrencyRuble';
import { formatPrice } from "../../../utils";
import { LoadingButton } from "@mui/lab";

type Props = {

};

const _createWideCard = (item: any, patch?: null | { count: number }) => {
    const { orderId } = useParams();
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(orderId);

    if (patch?.count !== undefined) {
        item = { ...item, item: { ...item.item, quantity: patch.count } };
    }

    return (
        <OrderWideCardRetail
            removed={patch === null}
            item={item}
            bonus={item.bonus}
            outOfStock={item.item?.count <= 0 && ([0, 1].includes(order.order.status) || item.snapshot.state === 2)}
        />
    );
}

const MyWideCard = observer((item: { item }) => {
    return _createWideCard(item.item);
});

const keyResolver = v => `${v.snapshot.id}_${(v.snapshot.price || 0).toFixed(2)}`;

const BonusBlock: FC<{
    orderId: string,
    useBonuses: boolean,
    onUseBonusesChange: (value: boolean) => void,
}> = (p) => {
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(p.orderId);

    if (order.order.bonus) {
        return (<>
            <Divider variant="fullWidth" />
            <BonusInfo hidePolicyLink
                mode="order"
                sx={{ maxWidth: { xs: "100%", md: 290 } }}
                added={order.order?.bonus.added}
                subtracted={order.order.bonus.subtracted}
            />
        </>);
    }

    if (!order.order.bonusAvailable || [7, 11, 12].includes(order.order.status))
        return undefined;

    return (
        <BonusInfo hidePolicyLink
            mode="cart"
            available={order.order.bonusAvailable}
            useBonuses={p.useBonuses}
            onUseBonusesChange={p.onUseBonusesChange}
        />
    );
}

let timerHandle: number = null;

const OrderItemView: FC<Props> = () => {
    const { orderId } = useParams();
    const orderStore = useContext(OrderContext);
    const order = orderStore.getOrder(orderId);
    const [patch, setPatch] = useState({} as Patch<string, { count: number }>);
    const [useBonuses, setUseBonuses] = useState(false);

    const theme = useTheme();

    const items = order?.items;
    const operations = order?.operations;

    let totalPrice = order?.order.totalPrice || 0;
    if (useBonuses) {
        totalPrice -= order.order.bonusAvailable;
    }

    const isPaymentProcess = [11, 12].includes(order?.order.status);

    useEffect(() => {

        if (isPaymentProcess) {
            timerHandle = window.setTimeout(() => {
                timerHandle = null;
                window.location.reload();
            }, 5000);
        }

        return () => {
            if (timerHandle !== null)
                window.clearTimeout(timerHandle);
        };
    });

    return (<>
        <Grid container columnSpacing={3} direction={{ xs: 'column-reverse', md: 'row' }} sx={{ pt: 2 }}>
            <Grid xs={12} md={7} lg={8}>
                {items?.list.length > 0 ? (
                    <AppList
                        patch={patch}
                        component={MyWideCard}
                        items={items}
                        loadNext={() => orderStore.orderItemsLoadNextPage(orderId)}
                        keyResolver={keyResolver}
                        groups={[
                            item => ({ text: 'Добавлено оператором', condition: () => item.snapshot.state === 1 }),
                            item => ({ text: 'Убрано оператором', condition: () => item.snapshot.state === 2 }),
                        ]}
                        events={{
                            onCountChange: (item: any, v: number) => {
                                const key = keyResolver(item.item);
                                const p = { ...patch };
                                if (!p[key]) p[key] = { count: 0 };

                                p[key].count = v;
                                setPatch(p);
                            },
                            onRemoveClick: (item: any) => {
                                const key = keyResolver(item.item);

                                setPatch({ ...patch, [key]: null });
                            }
                        }}
                    />
                ) : undefined}
                {operations?.list.length > 0 ? (
                    <Box>
                        <Typography sx={{ py: 1 }}>Услуги:</Typography>
                        <AppList
                            component={MyWideCard}
                            items={operations}
                            loadNext={() => orderStore.orderItemsLoadNextPage(orderId)}
                            keyResolver={keyResolver}
                        />
                    </Box>
                ) : undefined}
            </Grid>
            <Grid xs={12} md={5} lg={4}>
                {order?.order ? (
                    <Stack sx={{ p: 2, mb: { xs: 4, md: 0 } }} component={Paper} direction="column" elevation={1} spacing={2}>
                        {order.order.payUntil && (
                            order.order.payUntil.isBefore(dayjs())
                                ? <Typography color={theme.palette.error.main} children="Требуется оплата" />
                                : <Typography>
                                    <Box component="span" sx={{ color: theme.palette.warning.main }}>Оплатить до:</Box>{" "}
                                    <Date date={order.order.payUntil} />
                                </Typography>
                        )}
                        <Box sx={{ display: "flex", gap: 1 }}>
                            <Typography fontWeight="bold">Заказ создан:</Typography>
                            <Date variant="date" date={order.order.date} />
                        </Box>

                        {order.order.vehicleName && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography fontWeight="bold">Обслуживаемое ТС:</Typography>
                                <Typography>{order.order.vehicleName}</Typography>
                            </Box>
                        )}
                        {order.order.vehicleName && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography fontWeight="bold">Номер ТС: </Typography>
                                <Typography>{order.order.vehicleStateCode}</Typography>
                            </Box>
                        )}
                        {order.order.vehicleName && (
                            <Box sx={{ display: "flex", gap: 1 }}>
                                <Typography fontWeight="bold">Пробег:</Typography>
                                <Typography>{order.order.vehicleMileage}</Typography>
                            </Box>
                        )}

                        {(order.payer || order.recipient) && (<>
                            <Divider variant="fullWidth" />
                            {order.payer && (<>
                                <Typography variant="subtitle1">
                                    Плательщик
                                </Typography>
                                <Typography variant="body1">
                                    {order.payer.name}
                                </Typography>
                            </>)}
                            {order.recipient && (<>
                                <Typography variant="subtitle1">
                                    Грузополучатель
                                </Typography>
                                <Typography variant="body1">
                                    {order.recipient.name}
                                </Typography>
                            </>)}
                        </>)}
                        {order.order.cashId && order.order.status === 7 && (
                            isPaymentProcess ? (
                                <Skeleton variant="rounded" width={140} height={20} />
                            ) : (
                                <RouterLink to={`/receipt/cash/${order.order.cashId}/pdf`} target="_blank">
                                    Скачать чек
                                </RouterLink>
                            )
                        )}
                        {order.order.techServiceId && (
                            isPaymentProcess ? (
                                <Skeleton variant="rounded" width={140} height={20} />
                            ) : (
                                <RouterLink to={`/receipt/service/${order.order.techServiceId}/pdf`} target="_blank">
                                    Скачать акт
                                </RouterLink>
                            )
                        )}
                        {/* <GetOrderInvoiceLink
                            id={order.order.id}
                            status={order.order.status}
                            payUntil={order.order.payUntil}
                        /> */}


                        <BonusBlock
                            orderId={orderId}
                            useBonuses={useBonuses}
                            onUseBonusesChange={setUseBonuses}
                        />
                        {order.order.serviceAddress && (<>
                            <Typography variant="subtitle1">
                                Адрес обслуживания
                            </Typography>
                            <Typography variant="body1">
                                {order.order.serviceAddress}
                            </Typography>
                        </>)}
                        <Divider variant="fullWidth" />
                        {order.order.comment && (<>
                            <Divider variant="fullWidth" />
                            <Typography variant="subtitle1">
                                Комментарий
                            </Typography>
                            <Typography variant="body1">
                                {order.order.comment}
                            </Typography>
                            <Divider variant="fullWidth" />
                        </>)}
                        <TotalPrice label="Итог:" value={totalPrice} />
                        {[7, 11, 12].includes(order.order.status) ? (
                            order.order.status === 7 ? undefined : (<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <CircularProgress /> <span>Оплата в обработке...</span>
                            </Box>)
                        ) : (
                            <RouterLink
                                to={`/receipt/${orderId}/checkout` + (useBonuses ? '?useBonuses=true' : '')}
                                reloadDocument
                            >
                                <LoadingButton
                                    variant="contained"
                                    endIcon={<RubleIcon />}
                                >
                                    Оплатить {formatPrice(totalPrice, false)}
                                </LoadingButton>
                            </RouterLink>
                        )}
                    </Stack>
                ) : (
                    <LoadingPaper />
                )}
            </Grid>
        </Grid>
    </>);
};

export default observer(OrderItemView);