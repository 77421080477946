import { Stack, Box, Typography, Switch, useTheme, StackProps } from "@mui/material";
import BonusIcon from "../../../icons/points.svg"
import { formatPrice } from "../../../utils";
import { Link } from "react-router-dom";

const $ = (val: number) => formatPrice(val, false);

type Props = {
    readonly mode: 'profile' | 'cart' | 'order' | 'profileRetail';
    readonly available?: number;
    readonly balance?: number;
    readonly inactive?: number;
    readonly toGive?: number;

    readonly added?: number;
    readonly subtracted?: number;

    readonly useBonuses?: boolean;
    readonly onUseBonusesChange?: (v: boolean) => void;
    readonly hidePolicyLink?: boolean;
} & StackProps;


const BonusInfo = (p: Props) => {
    const { available, balance, inactive, toGive, useBonuses, added, subtracted, onUseBonusesChange, sx,
        ...stackProps
    } = p;

    const theme = useTheme();

    return (
        <Stack sx={{ ...sx, backgroundColor: "#F9FAFC", border: "1px solid #E6E8F0", my: "15px", px: '12px', py: '15px', borderRadius: '12px' }} {...stackProps}>
            <Box display="flex" alignItems="center" pb="15px" gap={1}>
                <Box component={BonusIcon} />
                <Typography variant="body1" fontWeight="bold" color={theme.palette.info.contrastText}>Баллы</Typography>
            </Box>
            <Box>
                {p.balance === undefined ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" pb={1}>
                        <Typography>Бонусные баллы:</Typography>
                        <Typography color={theme.palette.info.contrastText}>{$(p.balance)}</Typography>
                    </Box>
                )}
                {p.inactive === undefined ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" pb={1}>
                        <Typography>Неактивные:</Typography>
                        <Typography color={theme.palette.info.contrastText}>{$(p.inactive)}</Typography>
                    </Box>
                )}
                {p.toGive === undefined ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" pb={1}>
                        <Typography>Накопится:</Typography>
                        <Typography color={theme.palette.info.contrastText}>{p.toGive}</Typography>
                    </Box>
                )}
                {p.available === undefined ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" pb={1}>
                        <Typography>Доступно{p.mode === 'profile' || 'retail' ? ' для списания' : ''}:</Typography>
                        <Typography color={theme.palette.info.contrastText}>{$(p.available)}</Typography>
                    </Box>
                )}
                {p.mode != 'cart' ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                        <Typography>Списать:</Typography>
                        <Switch
                            disabled={!p.available}
                            checked={p.useBonuses}
                            onChange={p.onUseBonusesChange ? e => p.onUseBonusesChange(e.target.checked) : undefined}
                        />
                    </Box>
                )}
                {p.subtracted === undefined ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                        <Typography>Списано:</Typography>
                        <Typography color={theme.palette.info.contrastText}>- {$(p.subtracted)}</Typography>
                    </Box>
                )}
                {p.added === undefined ? undefined : (
                    <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
                        <Typography>Начислено:</Typography>
                        <Typography color={theme.palette.info.contrastText}>+ {$(p.added)}</Typography>
                    </Box>
                )}
            </Box>
            {p.hidePolicyLink ? undefined : (
                <Link target="_blank" to="/static/bonus-agreement.pdf">
                    <Typography
                        sx={{
                            color: theme.palette.info.contrastText,
                            '&:hover': {
                                textDecoration: "underline",
                            }
                        }}
                    >
                        Система лояльности
                    </Typography>
                </Link>
            )}

        </Stack>
    );
}

export default BonusInfo;