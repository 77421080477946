import { FC } from "react";
import { OrderItem } from "../../../../store/order";
import { observer } from "mobx-react-lite";
import WideCardRetail from "../WideCardRetail";

type Props = {
    readonly item: OrderItem;
    readonly outOfStock?: boolean;
    readonly editMode?: boolean;
    readonly removed?: boolean;
    readonly bonus?: {
        value: number;
        deactivated: boolean;
    };
}

const OrderWideCardRetail: FC<Props> = (p) => {

    return (
        <WideCardRetail
            imgSrc={p.item.item?.imgList}
            label={p.item.snapshot.label}
            highlighted={p.item.snapshot.state === 1}
            faded={p.removed || p.item.snapshot.state === 2}
            articl={p.item.item?.articl}
            cnpCode={p.item.item?.cnp_code}
            packageSize={p.item.item?.pkg}
            stock={p.item.item?.count}
            defaultStock={p.item.item?.stockDefaultCount}
            defaultStockDate={p.item.item?.stockDefaultDate}
            inCartCount={0}
            count={p.item.snapshot.quantity}
        />
    );
}

export default observer(OrderWideCardRetail);