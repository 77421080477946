import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { BonusContext, UserContext } from "../../../../store";
import {
    Box,
    Paper,
    Typography,
    useMediaQuery,
    useTheme,
    Button
} from "@mui/material";
import BonusInfo from "../../../../components/bonus/BonusInfo";
import QRIdentity from "../../../components/QRIdentity";
import { Link } from "react-router-dom";

const InfoViewRetail = () => {

    const bonus = useContext(BonusContext);
    const user = useContext(UserContext);
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));
    const data = user.user;

    return (<>
        <Paper sx={{ position: "absolute", left: "50%", transform: "translate(-50%, 0)", maxWidth: 426, width: '100%', p: 3, display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", my: 3 }}>
            <QRIdentity sx={{ maxWidth: "200px", pb: 3 }} />
            <Typography textAlign="center" fontWeight="bold" color="#3F8CFF" variant={isSmall ? "h6" : "h5"} children={user.user?.fullName} />
            <Typography sx={{ pt: "5px", pb: "15px" }} color="#081735" fontSize="18px">Логин: {data.login}</Typography>
            <Box width="100%">
                <BonusInfo
                    hidePolicyLink
                    mode="profileRetail"
                    balance={bonus.status?.totalBalance || 0}
                    available={Math.max(bonus.status?.balance ?? 0, 0)}
                    inactive={bonus.status?.inactive ?? 0}
                />
            </Box>
            <Button fullWidth variant="outlined" sx={{ mt: "15px", py: "8px" }}>
                <Link target="_blank" to="/static/retail/bonus-agreement.pdf">
                    <Typography
                        sx={{
                            color: theme.palette.info.contrastText,
                            '&:hover': {
                                textDecoration: "underline",
                            },
                            fontWeight: 'bold'
                        }}
                    >
                        Система лояльности
                    </Typography>
                </Link>
            </Button>
        </Paper>
    </>);
}

export default observer(InfoViewRetail);
