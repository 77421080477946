import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import Heading from "../HeadingView";
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Divider,
    Paper,
    Skeleton,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LoadingPaper from "../../LoadingPaper";
import { CartContext, CatalogContext, UserContext } from "../../../store";
import { useNavigate, useParams } from "react-router-dom";
import Stepper from "../../Stepper";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Price from "../../Item/Price";


type Props = {

}

const CatalogCardView: FC<Props> = () => {
    const theme = useTheme();
    const { code } = useParams();
    const catalog = useContext(CatalogContext);
    const cart = useContext(CartContext);
    const user = useContext(UserContext);
    const [amount, setAmount] = useState(1);
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const item = catalog.getItem(code);

    return (<>
        <Heading />
        <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: "column", lg: "row" }, pb: 5, gap: 2 }}>
            <Box width="100%">
                {item !== undefined ? (<>
                    <Typography sx={{ mb: 4 }} variant="h5">
                        {item.label}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 2, flexDirection: { xs: "column", md: "row" }, alignItems: { xs: "center", md: "start" } }}>
                        <Box onClick={handleClickOpen} sx={{ display: "flex", justifyContent: "center", transition: "0.3s", '&:hover': { scale: 1.1, cursor: "pointer" } }}>
                            <Box component="img" src={item.imgCard} alt={item.label} sx={{ p: 1, width: "310px" }} />
                        </Box>
                        <Stack sx={{ width: "100%", maxWidth: 510 }} spacing={2}>
                            {item.props?.length ? item.props.map(prop => (
                                <Box
                                    key={prop.name}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-start',
                                        flexWrap: 'wrap',
                                        gap: 1,
                                    }}
                                >
                                    <Typography sx={{ color: "#081735", fontWeight: "800", }}>{prop.name}:</Typography>
                                    {prop.values.map((v, index) => {
                                        const shouldAddComma = index !== prop.values.length - 1;
                                        return (
                                            <Typography key={index}>
                                                {v.value}{shouldAddComma && ', '}
                                            </Typography>
                                        );
                                    })}

                                </Box>
                            )) : undefined}
                            {item.props?.length === 0 && (
                                <Typography variant="body1">
                                    <strong>На данный момент список характеристик пуст</strong>
                                </Typography>
                            )}
                        </Stack>
                    </Box>
                </>) : (
                    <LoadingPaper />
                )}
            </Box>
            <Box minWidth={250}>
                {item !== undefined ? (<>
                    <Paper sx={{ p: 2, mt: { xs: 4, md: 0 } }} elevation={1}>
                        <Stack fontSize="0.88rem" direction="column" spacing={2}>
                            <Price value={item.price} sx={{ justifyContent: "start", '.MuiTypography-root': { color: theme.palette.warning.main, fontSize: "1.5rem" } }} />
                            <Divider />
                            <Box>
                                <Typography>
                                    Остаток на складе: {item.count}
                                </Typography>
                            </Box>
                            <Divider />
                            <Stack gap={1}>
                                <Typography>Количество в упаковке: {item.pkg}</Typography>
                                <Typography>Артикул: {item.articl}</Typography>
                                <Typography>
                                    {item.cnp_code && (<>Код ДА: {item.cnp_code} <br /></>)}
                                </Typography>
                            </Stack>
                            <Divider />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Stepper value={amount} onChange={setAmount} />
                                <LoadingButton
                                    size="small"
                                    variant="contained"
                                    loading={isAddingToCart}
                                    sx={{ backgroundColor: theme.palette.info.contrastText }}
                                    onClick={() => {
                                        setIsAddingToCart(true);
                                        cart.addWithAmount(item, amount, user.id)
                                            .finally(() => {
                                                setAmount(1);
                                                setIsAddingToCart(false);
                                            });
                                    }}
                                >
                                    В корзину
                                </LoadingButton>
                            </Box>
                        </Stack>
                    </Paper>
                    <Box sx={{ p: 2, px: { xs: 0, md: 2 } }}>
                        <Button
                            sx={{
                                width: '100%',
                                color: theme.palette.info.contrastText,
                                borderColor: theme.palette.info.contrastText,
                            }}
                            variant="outlined"
                            onClick={() => navigate(-1)}
                        >
                            Вернуться назад
                        </Button>
                    </Box>
                </>) : (
                    <LoadingPaper />
                )}
            </Box>
        </Box>
        {item?.description ? (<>
            <Paper sx={{ p: 2, mb: 4 }}>
                <Typography sx={{ fontSize: "18px", fontWeight: "bold", color: "#8F95B2", mb: 2 }}>
                    <strong>Описание</strong>
                </Typography>
                <Typography dangerouslySetInnerHTML={{ __html: item.description }} />
            </Paper>
        </>) : ''}
    </>);
}

export default observer(CatalogCardView);
